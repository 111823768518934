<template>
  <b-card no-body class="overflow-hidden">
    <b-row no-gutters>
      <b-col sm="12" md="5">
        <b-card-img-lazy
          height="150px;"
          :src="SPENV.S3_URL + '/images/paths-thumbs/path-' + learningPath.rowid + '.jpg'"
          :alt="learningPath.call_to_action"
          onerror="this.onerror=null;this.src='/static/images/courses/placeholder-transparent.png'"
          class="rounded-0" />
      </b-col>
      <b-col sm="12" md="7">
        <b-card-body :title="learningPath.call_to_action">
          <small>{{ learningPath.sub_title }}</small>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: 'LearningPathTile',
  props: {learningPath: { type: Object, default: null }},
};
</script>

<style>
</style>
