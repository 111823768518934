<template>
  <div class="info-container" :class="theaterMode ? 'col-lg-7' : 'col-lg-8'">
    <div>
      <ul class="nav nav-tabs-material" role="tablist">
        <li v-if="learningPaths.length" class="nav-item">
          <a class="nav-link" :class="{'active show': tab === 'learningPaths'}" href="javascript:void(0)"
             @click="tab='learningPaths'">
            Learning Paths
          </a>
        </li>
        <li v-if="lesson.rowid === 28826" class="nav-item">
          <a class="nav-link" href="javascript:void(0)" :class="{ 'active show': tab === 'transcript' }"
             @click="tab = 'transcript'"> Transcript </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" :class="{ 'active show': tab === 'otherVersions' }"
             @click="tab = 'otherVersions'"> Other versions </a>
        </li>
        <li v-if="course.cert && course.cert.id" class="nav-item">
          <a class="nav-link" :class="{ 'active show': tab === 'cert' }" href="javascript:void(0)"
             @click=" tab = 'cert'; setCertNotified(course); ">
            Technical Certificate
            <span v-if=" course.cert.status === 'close' && !course.cert.date_notified ">
              <font-awesome-layers>
                <font-awesome-icon
                  icon="circle"
                  style="color: red;"
                  fixed-width />
                <font-awesome-icon
                  icon="bell"
                  style="color: white;"
                  transform="shrink-7"
                  fixed-width />
              </font-awesome-layers>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a
            v-if="course.show_partfiles"
            class="nav-link"
            :class="{ 'active show': tab === 'files' }"
            href="javascript:void(0)"
            @click="tab = 'files'">Exercise files</a>
        </li>
        <li v-if="lesson.rowid === 28828" class="nav-item">
          <a
            class="nav-link"
            href="javascript:void(0)"
            :class="{ 'active show': tab === 'transcript' }"
            @click="tab = 'transcript'"> Transcript </a>
        </li>
      </ul>
      <div class="tab-content">
        <div v-if=" lesson.rowid === 28826 || lesson.rowid === 28828 " class="tab-pane" :class="{ 'active show': tab === 'transcript' }">
          <div class="tab-pane-container">
            <div v-if="lesson.rowid === 28826">
              <p>
                So, what is SOLIDWORKS? Well, this may seem like an obvious question. It’s a CAD tool, right?
                Besides being a very user-friendly CAD tool, I’d like to tell you a little more about how it
                behaves before jumping right in.
              </p>
              <p>
                SOLIDWORKS is a Parametric, Feature Based system that allows you to create 3D Parts,
                Assemblies, and 2D Drawings. A parametric modeler behaves differently than other more primitive
                CAD tools that are object driven. What I mean is, in a parametric modeler, everything is
                controlled by either parameters, dimensions, or relationships. For example, if you want to move
                the position of a hole that’s in a block, in a parametric system you would change the dimension
                or relation that controls its position. The parameters and relationships you set up allow you to
                have control as a designer over something called design intent. The term “Design intent”
                basically describes the way your design will behave depending on the way you design it. By using
                parameters and relationships this gives you ultimate control over the model.
              </p>
              <p>
                Here I have a block with a hole in the center. You can see the dimensions place the hole at
                the center of the block. But what if the size of the block changes? Well, you can see here that
                the hole is no longer centered, since the position of the hole is defined by these 2 dimensions
                here. But what if I want to center this hole so that no matter what size the rectangle is, the
                circle will always be centered? In a parametric modeler, it’s also possible to use
                relationships. Instead of using dimensions to locate the hole, I can use a line from corner to
                corner, and set the center of the hole to be the midpoint of the line. Now the midpoint relation
                is controlling the position of the hole, rather than a dimension. So no matter what the size of
                the rectangle, the circle will always be centered.
              </p>
              <p>
                This is just one example of the countless ways to capture your design intent when modeling in
                3D and these geometric relationships throughout the course. You can see how modeling in a
                parametric environment can be very powerful. The other huge advantage of this type of system is
                that you also get associativity between your parts, assemblies, and drawings, meaning if you
                make a change to the design of a part, the change will take effect in any assemblies that it’s a
                part of, and its 2D drawing will update automatically as well. You can also make the same design
                change from the 2D drawing, and the part will update, as well as the assembly. This associative
                behavior saves you lots of time when you make design changes so that you only have to make the
                change once, and all associated items will automatically update appropriately.
              </p>
            </div>
            <div v-if="lesson.rowid === 28828">
              <p>
                When you begin creating sketches, parts, and assemblies in SOLIDWORKS, it is necessary to
                rotate, pan, and zoom around the graphics area.
              </p>
              <p>
                First, let me show you how to rotate the model. I’ll simply press and hold the middle mouse
                button, and drag the pointer around the screen. Notice the model reacting to each direction I
                move. Many new users can have trouble getting the desired result from rotating. They might start
                using circular motions with the mouse until eventually it ends up in the right orientation. My
                advice is to simply limit your rotate command to vertical and horizontal directions.
              </p>
              <p>
                Here’s what I mean, watch as I move the mouse in only the up and down directions at one time.
                Then, from here I can switch to rotating the model in the left and right directions. This way I
                know exactly which way the model will rotate. Next, let me show you how to pan the model around
                the screen. To do this, press and hold the control key and then the middle mouse button. From
                here you can drag the model around the graphics area.
              </p>
              <p>
                To zoom, simply use the mouse wheel to zoom in and out. One thing about zooming, when you roll
                the mouse button backwards to zoom in, SOLIDWORKS zooms in on the position of the mouse cursor.
                New users sometimes have a hard time with this and end up zooming the part outside of the
                graphics area. Let me show you what I mean. Say I want to zoom in on this corner of the part. If
                I leave my mouse in this spot and keep zooming in, notice the part eventually leaves the
                graphics area. This is because the mouse is positioned over the white background immediately
                after I start zooming in, which means I’ll be zooming in on that spot in the background and not
                the model. If I want to keep this from happening, I must relocate the cursor to the spot I want
                to zoom to every few mouse wheel clicks while zooming in.
              </p>
              <p>
                Another tool that I'd like to mention is “zoom to fit”, which can be helpful if you somehow
                lose the part in the graphics area. There are 2 ways to easily access it. First, the F key on
                your keyboard is a predefined hotkey for Zoom to Fit. This is a hotkey that most veteran
                SOLIDWORKS users remember, but since most of your other view manipulation tools are done with
                the mouse, you can also zoom to fit by double clicking the middle mouse button, and the part
                will return zoomed to the middle of the screen. If your mouse has a scroll wheel, you can
                double-click it for “Zoom to Fit” to take effect. There are quite a few more view manipulation
                options available in SOLIDWORKS, and I don’t want to overwhelm you by trying to show you all of
                them right now, but there is one final option I’d like to show you. SOLIDWORKS also has many
                “standard” views that you can quickly access to look at your model from a certain predefined
                orientation. There are several ways to access these views, but one easy way for a new user to do
                so is by pressing the space bar. When I do, a pop up box appears listing the standard views and
                I can click on the name of the view to automatically switch to it.
              </p>
            </div>
          </div>
        </div>
        <div class="tab-pane" :class="{ 'active show': tab === 'otherVersions' }">
          <div class="tab-pane-container">
            <div class="tab-body">
              <div v-if="course && course.other_versions && course.other_versions.length > 0 ">
                <div v-for="altCourseVersion in _.orderBy( course.other_versions, ['year'], 'desc' )" :key="altCourseVersion.rowid">
                  <router-link :to="{ name: 'course', params: { 'swslug': $route.params.swslug, 'courseslug': altCourseVersion.slug}, query: processQueryParams()}" class="btn-link">
                    {{ altCourseVersion.version_display || altCourseVersion.year }}
                  </router-link>
                </div>
              </div>
              <div v-else class="text-muted">
                There are no other versions of this course.
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane" :class="{ 'active show': tab === 'cert' }">
          <div class="tab-pane-container">
            <div class="tab-body">
              <div v-if="!course.cert.id">
                There is no certificate for this course
              </div>
              <div v-if="course.cert.id">
                <p v-if="!course.cert.status">
                  Technical Certificate available
                </p>
                <p v-if="course.cert.status === 'close'">
                  You're so close to earning your SolidProfessor Technical Certificate!
                </p>
                <p v-if="course.cert.status === 'earned'">
                  Congratulations you've earned the SolidProfessor Technical Certificate!
                </p>
                <p class="list-heading">
                  Requirements
                </p>
                <ul class="list-unstyled">
                  <li>
                    <font-awesome-icon
                      icon="check"
                      class="text-primary"
                      fixed-width
                      :class="{ invisible: course.num_viewed !== course.num_lessons }" />
                    View all lessons
                  </li>
                  <li v-if="course.has_test">
                    <font-awesome-icon
                      icon="check"
                      class="text-primary"
                      fixed-width
                      :class="{ invisible: course.test_score < course.cert.score_threshold }" />
                    Test score of {{ course.cert.score_threshold }}% or higher
                  </li>
                  <li v-if="course.cert.status === 'earned'">
                    <a :href=" SPENV.APP_URL + '/lmsapp/achievements/cert/' + course.cert.id ">View certificate</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tab-aside">
              <b-img-lazy v-if="course.cert.status === 'earned'" :src=" SPENV.APP_URL + '/certs/assets/images/badges/cert_badge.png' " alt="certificate icon" />
              <b-img-lazy v-if=" course.cert.id && course.cert.status !== 'earned' " :src=" SPENV.APP_URL + '/certs/assets/images/badges/ribbon-grey-lg.png'" alt="certificate icon" />
            </div>
          </div>
        </div>
        <div v-if="course.show_partfiles" class="tab-pane" :class="{ 'active show': tab === 'files' }">
          <a class="btn btn-link" href="javascript:void(0)" @click="getPartfilesByCourseId(course.id)">
            <font-awesome-icon icon="cloud-download-alt" />
            Download Files</a>
        </div>
        <div class="tab-pane" :class="{'active show': tab === 'learningPaths'}">
          <h5>Suggested for You</h5>
          <div v-if="learningPaths">
            <div v-for="learningPath in learningPaths" :key="learningPath.rowid" class="m-3 card heavy-shadow">
              <router-link :to="{'name': 'path', params: {'pathslug': learningPath.slug}}" class="no-router-links">
                <LearningPathTile :learning-path="learningPath" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseComponent from '@/components/BaseComponent';
import _ from 'lodash';
import LearningPathTile from '@/components/LearningPaths/LearningPathTile.vue';
import axios from 'axios';

export default {
  name: 'CourseInfo',
  components: {LearningPathTile},
  extends: BaseComponent,
  props: {
    course: {
      type: Object,
      default () {
        return {};
      }
    },
    learningPaths: {
      type: Array,
      default() {
        return [];
      }
    },
    lesson: {
      type: Object,
      default() {
        return {};
      }
    },
    theaterMode: {
      type: Boolean,
      default() {
        return false;
      },
    }
  },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    // Use lodash in template
    _() {
      return _;
    },
  },
  watch: {
    'learningPaths': function (val, oldVal) {
      this.setDefaultTab();
    },
    'lesson': function (val, oldVal) {
      this.setDefaultTab();
    },
  },
  methods: {
    /**
     * Computes 'filterby' associative array
     *
     * @return {array} associative {'filterby': 'new'} or {}
     */
    processQueryParams() {
      if (this.$route.query['filterby']) {
        return {filterby: this.$route.query['filterby']};
      } else {
        return {};
      }
    },
    /**
     * Get the partfiles url for a course and do download
     *
     * @param {int} courseId
     * @return {void}
     */
    getPartfilesByCourseId (courseId) {
      let downloadWindow = window.open('', '_blank');
      axios.get(this.SPENV.APP_URL + '/api/v2/course/' + courseId + '/partfiles').then(
        (response) => {
          downloadWindow.location = response.data.url;
        })
        .catch(error => {
          reject(error);
        });
    },
    /**
     * finds the cert in the db and updates the date_notified jcervantes 05/17/18
     * @param {obj} course
     * @return {void}
     */
    setCertNotified (course) {
      if (!course.cert.date_notified && course.cert.status === 'close') {
        this.$store.dispatch('software/setCertNotified', course);
      }
    },
    setDefaultTab () {
      if (this.lesson.rowid === 28826) {
        this.tab = 'transcript';
      } else if (this.learningPaths.length) {
        this.tab = 'learningPaths';
      } else {
        this.tab = 'otherVersions';
      }
    },
  },
};
</script>
<style lang="scss">
</style>
