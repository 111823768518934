<template>
  <div class="html5-player" :style="{ height: height }"
       :class="theaterMode ? 'theater-lesson-height' : ''">
    <iframe
      id="html5_lesson"
      width="100%"
      height="100%"
      frameborder="0"
      :src="'https://lessons.solidprofessor.com/' + fileId + '/story.html?lessonID=' + lessonId +''" />
  </div>
</template>
<script>
import BaseComponent from '@/components/BaseComponent';

export default {
  name: 'InteractiveLesson',
  extends: BaseComponent,
  props: {
    lesson: {
      type: Object,
      default () {
        return {};
      }
    },
    theaterMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      fileType: '',
      fileId: '',
      height: window.innerHeight / 2 + 'px',
      lessonId: '',
    };
  },
  watch: {
    'lesson': function (val, oldVal) {
      this.init();
      this.recordView();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.fileId = this.lesson.fileid.split('.')[0];
      this.fileType = this.lesson.fileid.split('.')[1];
      this.lessonId = this.lesson.rowid;
      this.recordView();
    },
    recordView () {
      if (this.isLoggedIn) {
        const lessonData = {
          lessonID: this.lesson.rowid,
        };
        this.$store.dispatch('software/recordLessonView', lessonData).then(
          (response) => {
            const lesson = {
              rowid: this.lesson.rowid,
            };
            this.$emit('record', {lesson: lesson, cert: response});
            this.analyticsClickEvent('Lesson', 'View', 'lessonId: ' + this.fileId + ', type: ' + this.fileType);
          },
        );
      }
    }
  },
};
</script>
<style lang="scss">
</style>
