<template>
  <div class="pdf" :style="{ height: height }">
    <div class="row h-100">
      <div class="col-5 my-auto">
        <b-img-lazy :src="SPENV.S3_URL + '/images/course-thumbs/pdf_bkg.jpg'" class="float-right" />
      </div>
      <div class="col-7 pt-3 my-auto">
        <div class="row">
          <div class="col-12">
            <p class="float-left">
              This lesson is presented in PDF format.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <a target="_blank"
               class="btn btn-primary text-white"
               @click="openDocument(SPENV.S3_URL + '/lessonfiles/' + course.documentName + '/' + fileId + '.' + fileType)">
              Download now
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseComponent from '@/components/BaseComponent';
import moment from 'moment';
import laravelConnector from '@/utils/mixins/laravelConnector.js';

export default {
  name: 'LessonDocument',
  extends: BaseComponent,
  mixins: [laravelConnector],
  props: {
    course: {
      type: Object,
      default () {
        return {};
      }
    },
    lesson: {
      type: Object,
      default () {
        return {};
      }
    },
  },
  data() {
    return {
      fileType: '',
      fileId: '',
      height: window.innerHeight / 2 + 'px',
      lessonId: '',
    };
  },
  watch: {
    'lesson': function (val, oldVal) {
      this.init();
      this.recordView();
    },
  },
  created() {
    this.init();
    this.recordView();
  },
  methods: {
    init() {
      this.fileId = this.lesson.fileid.split('.')[0];
      this.fileType = this.lesson.fileid.split('.')[1];
      this.lessonId = this.lesson.rowid;
    },
    /**
     * Opens the submitted URL in a new window and records the event with Churnzero
     * This is currently used for pdf downloads
     *
     * @param {string} URL -- fully qualified URL
     * @return {void}
     */
    openDocument (url) {
      this.recordDocumentAccessChurnzero();
      window.open(url);
    },
    /**
     * Preps Event body and sends to Laravel to process/schedule
     *
     * @return {void}
     */
    recordDocumentAccessChurnzero () {
      let body = {
        contactExternalId: this.user.id,
        action: 'trackEvent',
        actionSpecificData: {
          eventName: 'document-download',
          lesson_id: this.lessonid,
          eventDate: moment().toISOString()
        }
      };
      this.laravelConnection('POST', 'tasks/churnzero/record-action', null, body);
    },
    recordView () {
      if (this.isLoggedIn) {
        const lessonData = {
          lessonID: this.lesson.rowid,
        };
        this.$store.dispatch('software/recordLessonView', lessonData).then(
          (response) => {
            const lesson = {
              rowid: this.lesson.rowid,
            };
            this.$emit('record', {lesson: lesson, cert: response});
            this.analyticsClickEvent('Lesson', 'View', 'lessonId: ' + this.fileId + ', type: ' + this.fileType);
          },
          (err) => {
            console.log(error);
          },
        );
      }
    },
  },
};
</script>
<style lang="scss">
</style>
