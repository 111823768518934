<template>
  <div id="course" :class="loadingLesson ? 'wait' : ''">
    <!-- Top Panel w/breacrumb -->
    <div
      v-if="courseObj && selectedLesson"
      class="jumbotron jumbotron-fluid texture texture-green sub-header header-padding"
      :style="{backgroundImage: `linear-gradient(180deg, rgba(65, 65, 65, 0.9) 0%, rgba(65, 65, 65, 0.9) 100%), url('${SPENV.S3_URL}/images/software-jumbotrons/${softwareId}.jpg')`}">
      <div class="container">
        <Breadcrumbs :breadcrumb-prop="breadcrumb" />
        <h1 class="display-4 mt-2">
          {{ courseObj.name }}
        </h1>
        <p class="lead">
          {{ courseObj.description | striphtml }}
        </p>
        <div v-if="!user || !user.isMember" class="mt-4">
          <p>{{ courseObj.call_to_action }}</p>
          <a class="btn btn-primary ghost" :href="SPENV.WWW_URL + '/signup/plan?return_url=' + location">Sign up</a>
        </div>
      </div>
    </div>
    <!-- Video Container -->
    <div v-if="courseObj" class="container course">
      <b-alert v-if="courseObj.private === 1 && isEmployee" variant="warning" show>
        <font-awesome-icon icon="exclamation-triangle" size="lg" fixed-width
                           class="mr-4" />
        This course is marked <b>private</b> and might be incomplete. Please contact <i>content-dev</i> with any questions.
      </b-alert>
      <div class="row mb-4 no-gutters course-content">
        <div class="video-layout col">
          <div class="video-container" :class="isTheaterModeToggled ? 'col-lg-12' : 'col-lg-8'">
            <Player v-if="lessonType === 'video'"
                    :autoplay="videoPlayerAutoplay"
                    :course="courseObj"
                    :lesson="selectedLesson"
                    :modal-content="modalContent"
                    :theater-mode="isTheaterModeToggled"
                    @record="lessonViewed"
                    @last="lessonFinal"
                    @next="lessonNext" />
            <InteractiveLesson v-if="lessonType === 'html' || lessonType === 'file'"
                               :lesson="selectedLesson"
                               :theater-mode="isTheaterModeToggled"
                               @record="lessonViewed" />
            <LessonDocument v-if="lessonType === 'pdf'"
                            :course="courseObj"
                            :lesson="selectedLesson"
                            @record="lessonViewed" />
            <div class="row no-gutters course-controls">
              <div class="col video-controls">
                <span class="player-control mr-auto">
                  <a v-if="selectedLesson && selectedLesson.show_partfiles" href="javascript:void(0)"
                     @click="getPartfilesByLessonId(selectedLesson.rowid)">
                    <span class="label">LESSON FILE</span>
                    <font-awesome-icon class="player-control-icon" icon="cloud-download-alt" />
                  </a>
                </span>
                <span v-if="!endOfCourse && courseObj" class="player-control">
                  <b-link class="text-decoration-none" @click="lessonNext(selectedLesson.rowid)">
                    <span class="label">NEXT LESSON</span>
                    <font-awesome-icon class="player-control-icon" icon="step-forward" />
                  </b-link>
                </span>
                <span v-if="isLoggedIn" class="toggle toggle-on">
                  <b-link class="text-decoration-none" @click="toggleAutoplay()">
                    AUTOPLAY
                    <font-awesome-icon class="switch" :icon="videoPlayerAutoplay ? 'toggle-on' : 'toggle-off'" />
                  </b-link>
                </span>
                <div class="layout-toggle d-none d-lg-block">
                  <a class="video-mode" href="javascript:void(0)" data-video-toggle="true"
                     data-video-toggle-theater-list-class="lessons-list-theater" @click="toggleTheaterMode">
                    <span class="label">LAYOUT</span> <i class="layout-icon layout-compact" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="lessons-container" :class="isTheaterModeToggled ? 'col-lg-5' : 'col-lg-4'">
            <div class="lessons-container-tight">
              <div class="lesson-list-header">
                <p>Lessons</p>
                <p class="ml-auto small">
                  Duration: {{ formatCourseDuration(courseObj.duration) }}
                </p>
                <div v-if="isLoggedIn" class="dropdown">
                  <b-dropdown right no-caret class="pl-2 pr-1 trans-btn">
                    <template slot="button-content">
                      <a id="filterDropdown" class="dropdown-toggle btn btn-link" href="javascript:void(0)"
                         @click="toggleFilterMenu()">
                        <font-awesome-icon icon="ellipsis-v" />
                      </a>
                    </template>
                    <!-- Show all -->
                    <b-dropdown-item :class="filterValue === 'all' ? 'active' : ''" @click="setLessonsFilter('all')">
                      <span class="filter-icon-placeholder">
                        <font-awesome-icon
                          fixed-width
                          :class="filterValue === 'all' ? 'visible' : 'invisible'"
                          icon="check" size="sm" />
                        <font-awesome-icon
                          fixed-width
                          size="sm"
                          icon="list" />
                        <span> Show all lessons</span>
                      </span>
                    </b-dropdown-item>
                    <!-- Toggle unviewed -->
                    <b-dropdown-item v-if="!disabled.viewed" :class="filterValue === 'viewed' ? 'active' : ''" @click="setLessonsFilter('viewed')">
                      <font-awesome-icon
                        fixed-width :class="filterValue === 'viewed' ? 'visible' : 'invisible'"
                        icon="check"
                        size="sm" />
                      <font-awesome-icon fixed-width icon="eye-slash" size="sm" />
                      <span> Show unviewed</span>
                    </b-dropdown-item>
                    <!-- Toggle new -->
                    <b-dropdown-item
                      v-if="!disabled.new"
                      :class="(filterValue == 'new' ? 'active': '')"
                      href="javascript:void(0)"
                      @click="setLessonsFilter('new')">
                      <font-awesome-icon fixed-width :class="(filterValue == 'new' ? 'visible': 'invisible')" icon="check"
                                         size="sm" />
                      <font-awesome-icon :icon="['fas', 'folder-open']" size="sm" />
                      <span> Show new</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="disabled.new"
                      :disabled="(disabled.new ? 'disabled' : '')"
                      href="javascript:void(0)">
                      <font-awesome-icon fixed-width :class="(filterValue == 'new' ? 'visible': 'invisible')" icon="check"
                                         size="sm" />
                      <font-awesome-icon :icon="['fas', 'folder-open']" size="sm" />
                      <span> Show new</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="disabled.viewed" :class="disabled.viewed ? 'disabled' : ''">
                      <font-awesome-icon
                        fixed-width :class=" filterValue === 'viewed' ? 'visible' : 'invisible' "
                        icon="check"
                        size="sm" />
                      <font-awesome-icon fixed-width icon="eye-slash" size="sm" />
                      <span> Show unviewed</span>
                    </b-dropdown-item>
                    <!-- Toggle bookmarked -->
                    <b-dropdown-item
                      v-if="!disabled.bookmarked"
                      @click="setLessonsFilter('bookmarked')">
                      <font-awesome-icon
                        fixed-width :class=" filterValue === 'bookmarked' ? 'visible' : 'invisible' "
                        icon="check"
                        size="sm" />
                      <font-awesome-icon fixed-width icon="bookmark" size="sm" />
                      <span> Show bookmarked</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="disabled.bookmarked"
                      :disabled="(disabled.bookmarked ? 'disabled' : '')">
                      <font-awesome-icon
                        fixed-width
                        :class=" filterValue === 'bookmarked' ? 'visible' : 'invisible' "
                        icon="check"
                        size="sm" />
                      <font-awesome-icon fixed-width icon="bookmark" size="sm" />
                      <span> Show bookmarked</span>
                    </b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item @click="setVideoType('html5')">
                      <font-awesome-icon :class=" !user.settings.video_type || user.settings.video_type === 'html5' ? 'visible' : 'invisible' " icon="check" size="sm" />
                      HTML5
                    </b-dropdown-item>
                    <b-dropdown-item @click="setVideoType('flash')">
                      <font-awesome-icon :class=" user.settings.video_type === 'flash' ? 'visible' : 'invisible' " icon="check" size="sm" />
                      Flash
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div v-if="filterValue !== 'all'" class="lesson-list-header">
                <h6>
                  Filtering by:
                  <span title="Remove filter" class="badge badge-primary hover-pointer increase-lineheight" @click="removeFilter()">{{ filterValue }}
                    <span aria-hidden="true">&times;</span>
                  </span>
                </h6>
              </div>
              <div v-if="courseObj && courseObj.sections" class="lesson-list-content">
                <b-list-group v-for="(section, $index) in applySectionFilters( courseObj.sections )" :key="section.id" class="lesson-list-group">
                  <b-list-group-item :id="'section-' + section.id" :key="section.id" class="section">
                    Section {{ $index + 1 }} - {{ section.name }}
                  </b-list-group-item>
                  <b-list-group-item
                    v-for="lesson in applyLessonFilters(section.lessons)"
                    :id="'lesson-' + lesson.id"
                    :key="lesson.id"
                    class="lesson-item"
                    :class="{ 'selected active disabled': lesson.id === selectedLesson.rowid, viewed: lesson.viewed }"
                    @click="loadLesson(lesson)">
                    <div v-if=" user && isLoggedIn && (!lesson.preview || user.isMember) " class="lesson-indicators" @click.prevent.stop="toggleBookmark(lesson)">
                      <span v-if="!lesson.bookmark"><font-awesome-icon fixed-width :icon="['far', 'bookmark']" size="lg" /></span>
                      <span v-if="lesson.bookmark"><font-awesome-icon fixed-width icon="bookmark" size="lg" /></span>
                    </div>
                    <div v-else class="lesson-indicators">
                      <span v-if="lesson.preview"><font-awesome-icon fixed-width icon="lock" size="lg" /></span>
                      <span v-else class="empty-fa" />
                    </div>
                    <div class="ms-flex-fix">
                      <div class="lesson-details">
                        <div class="row">
                          <div class="col-xs-3 ml-2 mr-2">
                            <h5><span v-if="lesson.id && computeIsNewFilter(lesson.id)" class="badge badge-primary">New</span></h5>
                          </div>
                          <div class="col-xs-9">
                            <p class="lesson-title">
                              {{ lesson.name | striphtml }}
                            </p>
                          </div>
                        </div>
                        <p class="lesson-desc small">
                          {{ lesson.description | striphtml }}
                        </p>
                        <div class="lesson-info">
                          <span v-if="lesson.filetype === 'video'" class="duration">{{ formatLessonDuration(lesson.duration) }}</span>
                          <span v-if="lesson.filetype !== 'video'" class="duration">{{ lesson.filetype }}</span>
                          <span v-show="lesson.viewed"><font-awesome-icon icon="eye" size="sm" /></span>
                        </div>
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <div v-if="isLoggedIn && courseObj.has_test" class="lesson-list-footer">
                <span class="test-label">Test your knowledge!</span>
                <button class="btn btn-light" type="button" @click="openTest()">
                  Begin Test
                </button>
              </div>
            </div>
          </div>
          <CourseInfo
            :course="courseObj"
            :learning-paths="learningPaths"
            :lesson="selectedLesson"
            :theater-mode="isTheaterModeToggled" />
        </div>
      </div>
    </div>
    <!-- Loader -->
    <sp-loader v-if="!courseObj" />

    <!-- Modal Component -->
    <b-modal
      v-model="lockedModalShow"
      centered
      hide-header
      hide-footer>
      <div class="get-access-modal">
        <h3>Get Access Now</h3>
        <p class="my-4">
          Become a SolidProfessor member to unlock this video today!
        </p>
        <div class="row justify-content-center mt-4">
          <div class="col-12 col-sm-6 mx-auto">
            <b-button-group vertical>
              <b-button variant="primary" :href="SPENV.WWW_URL + '/signup'">
                See Membership Plans
              </b-button>
            </b-button-group>
          </div>
          <div v-if="user && !isLoggedIn" class="col-12 col-sm-6 mx-auto">
            <b-button variant="outline-primary" :to="{ name: 'Login' , query: { return_url: $route.path }}">
              Already a member? <br>
              Log in
            </b-button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <b-button variant="link" @click="lockedModalShow = false">
              Cancel
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent';
import axios from 'axios';
import computeMetaData from '@/utils/seoTable.js';
import Breadcrumbs from '@/components/Breadcrumbs';
import CourseInfo from '@/components/CourseInfo';
import InteractiveLesson from '@/components/InteractiveLesson';
import LessonDocument from '@/components/LessonDocument';
import laravelConnector from '@/utils/mixins/laravelConnector.js';
import Player from '@/components/Player';
import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'Course',
  components: {Breadcrumbs, CourseInfo, InteractiveLesson, LessonDocument, Player},
  extends: BaseComponent,
  mixins: [laravelConnector],
  data() {
    return {
      allowedFilters: ['all', 'viewed', 'new', 'bookmarked'],
      breadcrumb: [],
      details: {
        cert: true,
        files: false,
      },
      disabled: {
        viewed: true,
        recommended: true,
        bookmarked: true,
        new: true,
      },
      endOfCourse: false,
      filteredLessons: [],
      filterValue: 'all',
      isTheaterModeToggled: false,
      lessonType: '',
      loaded: false,
      loadingLesson: false,
      modalContent: '',
      // Options for vue scrollto https://github.com/rigor789/vue-scrollto
      options: {
        container: '.lesson-list-content',
        easing: 'ease',
        cancelable: false,
      },
      showFilterMenu: false,
      showFilters: true,
      slugs: {
        software: this.$route.params.swslug,
        course: this.$route.params.courseslug,
        lessonid: this.$route.params.lessonid || '',
      },
      testComplete: null,
    };
  },
  metaInfo() {
    return computeMetaData(
      this.metaData,
      'course',
      this.courseObj,
      this.$route.params
    );
  },
  computed: {
    // Use lodash in template
    _() {
      return _;
    },
    courseChanges: {
      get: function () {
        return this.$store.getters['software/getCourseChanges'];
      },
      set: function (data) {
        this.$store.commit('software/setCourseChanges', data);
      }
    },
    courseObj() {
      return this.$store.getters['software/getCourseBySlug'](this.slugs);
    },
    isNewLesson: {
      get: function () {
        return this.$store.getters['software/getIsNew'];
      },
      set: function (data) {
        this.$store.commit('software/setIsNew', data);
      }
    },
    learningPaths: {
      get: function () {
        return this.$store.getters['software/getLearningPaths'];
      },
      set: function (data) {
        this.$store.commit('software/setLearningPaths', data);
      }
    },
    location () {
      return window.location;
    },
    metaData () {
      return (this.$store.getters['software/getMetaData']);
    },
    softwareName() {
      return this.$store.getters['software/getSoftwareName'](this.slugs);
    },
    softwareId() {
      return this.$store.getters['software/getSoftwareId'](this.slugs);
    },
    selectedLesson() {
      return this.$store.getters['software/getSelectedLesson'] || {};
    },
    videoPlayer() {
      return this.$store.getters['software/getVideoPlayer'];
    },
    videoPlayerAutoplay: {
      get: function(){
        return this.$store.getters['user/getUserSettingVideoAutoplay'];
      },
      set: function(newValue){
        this.$store.dispatch('user/updateAutoplaySetting', newValue);
      }
    },
    lockedModalShow: {
      get: function() {
        return this.$store.getters['software/getLockedModalShow'];
      },
      set: function(newValue) {
        this.$store.commit('software/setLockedModalShow', newValue);
      },
    },
  },
  watch: {
    'courseObj.cert.status': function(val, oldVal) {
      if (this.courseObj && this.courseObj.cert.show_notification) {
        // this will be sent to the player when a new cert is earned to open the modal inside the player
        this.setModalContent(this.courseObj);
      }
    },
    'selectedLesson': function (val, oldVal) {
      if (oldVal.rowid !== val.rowid) {
        this.lessonType = val.lesson_type;
        if (val.rowid) {
          this.getLearningPaths(val.rowid);
        }
      }
    },
    /**
     * FUNCTIONS TO BE RUN AFTER MAIN ASSETS ARE LOADED
     */
    'loaded': function (val, oldVal) {
      this.getLessonUpdates(this.processDataForLessonUpdates());
      this.setInitialFilter();
      this.isRecordableParam();
    },
  },
  created() {
    this.getMetaData();
    this.init(this.$route.params).then(() => {
      if (this.slugs.lessonid) {
        this.$store.dispatch('software/findLessonbyId', this.slugs.lessonid).then(
          (data) => {
            if (this.selectedLesson.course_slug != this.slugs.course || this.selectedLesson.software_slug != this.slugs.software) {
              this.fixSlugsFromSelectedLesson();
            }
            this.scrollToLesson(data.rowid);
          });
      }
    });
  },
  // Reset video player and update url on video change in same course
  beforeRouteUpdate(to, from, next) {
    this.slugs.software = to.params.swslug;
    this.slugs.course = to.params.courseslug;
    this.slugs.lessonid = to.params.lessonid;

    this.refreshUserData().then(
      () => {
        this.loadLessonById(to.params).then(
          () => {
            next();
          },
          error => {
            console.log(error);
            next();
          });
      },
    );
  },
  // Reset video player on route change
  beforeRouteLeave(to, from, next) {
    this.$store.commit('software/setSelectedLesson', {});
    this.$store.commit('software/setIsNew', false);
    next();
  },
  methods: {
    /**
     * Initialize course data from store or api
     */
    init (params) {
      return new Promise((resolve, reject) => {
        // if the full course object hasn't been retrieved, retrieve it
        if (this.courseObj && this.courseObj.sections) {
          this.createBreadcrumb(this.courseObj);
          this.createSlugs(params? params : this.$route.params);
          resolve();
        } else {
          return this.$store.dispatch('software/loadCourseBySlug', this.slugs).then(
            (response) => {
              this.createBreadcrumb(this.courseObj);
              this.createSlugs(params ? params : this.$route.params);
              resolve();
            },
            (error) => {
              if (this.slugs.lessonid) {
                this.$store.dispatch('software/findLessonbyId', this.slugs.lessonid).then(
                  (data) => {
                    if (this.selectedLesson.course_slug != this.slugs.course || this.selectedLesson.software_slug != this.slugs.software) {
                      this.fixSlugsFromSelectedLesson();
                    }
                    this.scrollToLesson(data.rowid);
                  });
              } else {
                console.log(error);
                this.$router.push({
                  name: 'software', params: {swslug: this.slugs.software},
                }).catch(err => {
                  this.$router.push({
                    name: 'library'
                  });
                });
              }
            },
          );
        }
      });
    },
    /**
     * Determines if User is SPweb/or other. SP Web does not have an origanization, and varaibles will need substituted
     *
     * @return {object} -- json formatted User information object to be sent to API
     */
    processDataForLessonUpdates () {
      let user_data = {
        admin_idc: (this.user && this.user.id) ? this.user.id : null,
        alias_id: null,
        drm_id: this.courseObj.id
      };
      if (this.user != null && this.user.organization != null && this.user.organization.hasOwnProperty('admin_idc') && this.user.organization.hasOwnProperty('user_id')) {
        user_data.admin_idc = this.user.organization.admin_idc;
        user_data.alias_id = this.user.organization.user_id;
      }

      return user_data;
    },
    /**
     * Retrieve Lesson 'last_big_update' data
     *
     * @param {object} parameters -- preformatted parameters
     *
     * @return {associative array} -- {'lesson_id': {'lesson_data'},...,}
     */
    getLessonUpdates (parameters) {
      if (parameters['admin_idc'] && parameters['drm_id']) {
        this.laravelConnection('GET', 'last-lesson-update', parameters, null)
          .then(
            response => {
              if (response.data) {
                this.courseChanges = response.data;
                this.isNewLesson = this.isNew(this.slugs.lessonid);
              }
            },
            error => {
              console.log(error);
            }
          );
      }
    },
    /**
     * Get LearningPath objects for submitted Lessons relational parent
     *
     * @param {string} lessonId --rowid of Lesson
     * @return {void}
     *
     * NOTE: Does not return, but assigns LearningPath computed property
     */
    getLearningPaths (lessonId) {
      this.laravelConnection('GET', 'lesson/learning-paths/' + lessonId)
        .then(
          response => {
            if (response.data) {
              this.learningPaths = response.data;
            }
          },
          error => {
            console.log(error);
          }
        );
    },
    /**
     * If a 'filterby' query param is detected, and it is an allowable filter, call function to record nav instance
     *
     * @return {void}
     */
    isRecordableParam () {
      if (this.$route.query['filterby'] && this.allowedFilters.includes(this.$route.query['filterby'])) {
        this.recordParamNavigation();
      }
    },
    /**
     * Builds json body and then calls Laravel to schedule Churnzero task
     */
    recordParamNavigation () {
      let body = {
        contactExternalId: this.user.id,
        action: 'trackEvent',
        actionSpecificData: {
          eventName: 'captureParam',
          queryParam: this.filterValue,
          eventDate: moment().toISOString()
        }
      };
      this.laravelConnection('POST', 'tasks/churnzero/record-action', null, body);
    },
    /**
     * Pulls query argument and asserts it is in allowed filters, if true applies the filter
     *
     * REQUIRES: query argument 'filterby'
     *
     * @return {void}
     */
    setInitialFilter () {
      if (this.$route.query['filterby']) {
        if (this.allowedFilters.includes(this.$route.query['filterby'])) {
          this.filterValue = this.$route.query['filterby'];
        }
      }
    },
    /**
     *
     */
    loadLessonById(params) {
      return new Promise((resolve, reject) => {
        this.init(params).then(() => {
          if (params.lessonid) {
            return this.$store.dispatch('software/findLessonbyId', params.lessonid).then(
              (data) => {
                if (this.selectedLesson.course_slug != this.slugs.course || this.selectedLesson.software_slug != this.slugs.software) {
                  return this.fixSlugsFromSelectedLesson();
                }
                this.scrollToLesson(data.rowid);
                this.getMetaData();
                this.loadingLesson = false;
                resolve();
              }).catch(err => {
              console.log(err);
              this.loadingLesson = false;
            });
          };
        });
      });
    },
    loadLesson (lesson) {
      this.loadingLesson = true;
      this.$router.push({
        name: 'course',
        params: {
          swslug: this.slugs.software,
          courseslug: this.slugs.course,
          lessonid: parseInt(lesson.id)
        },
        query: this.processQueryParams(),
      }).catch(err => {
        console.log(err);
      });
    },

    /**
     * Populate local scope slug vars and update url with lessonid
     */
    createSlugs (params) {
      return new Promise((resolve, reject) => {
        if (params.lessonid) {
          this.slugs.lessonid = parseInt(params.lessonid);
        } else {
          this.slugs.lessonid = this.courseObj.sections[0].lessons[0].id;
          this.loadLesson(this.courseObj.sections[0].lessons[0]);
        }
        this.slugs.software = params.swslug;
        this.slugs.course = params.courseslug;
        this.isNewLesson = this.isNew(this.slugs.lessonid);
        resolve();
      });

    },
    /**
     * Get API Meta Data
     * 'type' must correspond to Vue Course/Software page
     * 'slug' is the route param for the corresponding level above
     */
    getMetaData () {
      this.$store.dispatch('software/loadMetaData', {
        type: 'course',
        slug: this.$route.params.courseslug,
      });
    },
    /**
     * Turns the filter back to 'all', essentially removing all other filters
     */
    removeFilter () {
      this.filterValue = 'all';
    },
    /**
     * TODO: FIX BASE CASE AND REMOVE!
     * This is a work around for activating the 'new' filter, which are all off by default
     *
     * In the event we ever get around to reworking this file, I imagine the filters can be implemented much more
     * simply, and this wont be necessary.
     *
     * Currently, run as a comparison during the initial rendering of the Lesson list for each Lesson in the list,
     * if there is at least one 'new' lesson, will turn on the filter
     *
     * @param {integer} lessonId -- Lesson.rowid
     * @return {boolean}
     */
    computeIsNewFilter (lessonId) {
      if (this.isNew(lessonId)) {
        this.disabled.new = false;
        return true;
      }
      return false;
    },
    /**
     * Computes if the Lesson belonging to the submitted LessonId should be shown with a 'new' badge
     * IF the current Lesson has not been viewed, but there are views for the same Lesson in previous same-family version
     * AND IF the last same-family version occurred before the family-lesson's 'last_big_change'
     * RETURN true to indicate the lesson should be shown
     *
     * NOTE: this is entirely dependent on 'this.courseChanges' being populated
     *
     * @param {integer} lessonId -- Lesson.rowid of the lesson to check
     * @return {boolean} -- to show if Lesson should display badge
     */
    isNew (lessonId) {
      if (this.courseChanges && this.courseChanges[lessonId]) {
        if (this.courseChanges[lessonId]['last_view_lesson_year'] && !this.courseChanges[lessonId]['viewed_at']) {
          if (parseInt(this.courseChanges[lessonId]['last_view_lesson_year']) < parseInt(this.courseChanges[lessonId]['last_big_change'])) {
            return true;
          }
        }
      }
      return false;
    },
    /**
     * Format seconds to minutes and seconds format (e.g. 10m 38s)
     * @param {int} time in seconds
     * @return {string} formated time
     */
    formatLessonDuration (time) {
      let minutes = Math.floor(time / 60);
      let seconds = time % 60;
      return minutes + 'm ' + seconds + 's';
    },
    formatCourseDuration (time) {
      let hours = Math.floor(time / 60 / 60);
      let minutes = Math.floor(time / 60) - hours * 60;
      return hours + 'h ' + minutes + 'm';
    },
    /**
     * Create breadcrumb for course page
     * @param course
     * @param course
     */
    createBreadcrumb (course) {
      this.breadcrumb = [
        {label: 'Library', route: {name: 'library'}},
        {
          label: this.softwareName,
          route: {name: 'software', params: {swslug: this.slugs.software}},
        },
        {
          label: course.name,
          route: {
            name: 'software',
            params: {swslug: this.slugs.software, courseSlug: course.slug},
          },
        },
      ];
      // Only add year to breadcrumb if available
      if (course.year || course.version_display) {
        this.breadcrumb[2].label =
          course.name + ' ' + (course.version_display || course.year);
      }
    },
    /**
     * Toggle bookmark on lesson
     * @param {object} lesson object
     * @return {void}
     */
    toggleBookmark (lesson) {
      if (lesson.bookmark) {
        this.$store.dispatch('software/deleteBookmark', lesson);
        this.disabled.bookmarked = true;
      } else {
        this.$store.dispatch('software/saveBookmark', lesson);
      }
    },
    /**
     * Toggle auto play in DB if logged in and state if not
     * @return {void}
     * @return {void}
     */
    toggleAutoplay () {
      this.videoPlayerAutoplay = !this.videoPlayerAutoplay;
    },
    /**
     * Toggle video playback type
     * @return {void}
     * @param type
     */
    setVideoType (type) {
      this.user.settings.video_type = type;
      this.$store.dispatch('user/setVideoType', this.user.settings.video_type).then(
        (response) => {
          // noinspection JSDeprecatedSymbols
          window.location.reload(true);
        },
        (error) => {
          console.log(error);
        },
      );
    },
    /**
     * Get scrollto offset height for current section
     *
     * Only scroll if the element is available
     * (resolves potential conflict with setting filters)
     *
     * @param {int} sectionid
     * @return {int} offset height
     */
    getScrollToOffset (sectionid) {
      if (!sectionid) {
        return '';
      }
      let selectedSection = document.getElementById('section-' + sectionid);
      if (selectedSection) {
        return parseInt(window.getComputedStyle(selectedSection).height) * -1;
      }
    },
    /**
     * Scroll to selected lesson once video player is ready
     * @param {int} lessonid
     */
    scrollToLesson (lessonid) {
      if (lessonid) {
        let section = this.findSectionId(lessonid);
        this.options.offset = this.getScrollToOffset(section);
        window.setTimeout(() => {
          this.$scrollTo('#lesson-' + lessonid, 400, this.options);
        }, 100);
      }
    },
    /**
     * Switch to new lesson in list and update url with lessonid
     * @param sectionid
     * @param {obj} lesson
     * @return {void}
     */
    openLesson (sectionid, lesson) {
      // check if the lesson is not preview and send to the player to check perms jcervantes 05/31/18
      if (lesson.preview && this.user && !this.user.isMember) {
        // open locked lesson modal jcervantes 05/31/18
        this.lockedModalShow = true;
      } else {
        this.loadLesson(lesson);
      }
    },
    /**
     * Computes 'filterby' associative array
     *
     * @return {array} associative {'filterby': 'new'} or {}
     */
    processQueryParams() {
      if (this.$route.query['filterby']) {
        return {filterby: this.$route.query['filterby']};
      } else {
        return {};
      }
    },
    /**
     * Find section id by lesson id
     * @param {int} lessonid
     * @return {int} sectionid
     */
    findSectionId (lessonid) {
      if (this.courseObj) {
        let section = this.courseObj.sections;
        if (section) {
          for (let i = 0; i < section.length; i++) {
            for (let k = 0; k < section[i].lessons.length; k++) {
              if (section[i].lessons[k].id === lessonid) {
                return section[i].id;
              }
            }
          }
        }
      }
    },
    /**
     * Mark lesson as viwed in lessons list
     * @return {void}
     * @param data
     */
    lessonViewed (data) {
      this.disabled.viewed = true;
      let section = this.courseObj.sections;
      if (section) {
        for (let i = 0; i < section.length; i++) {
          for (let k = 0; k < section[i].lessons.length; k++) {
            this.applyLessonFilters(section[i].lessons[k]);
            if (section[i].lessons[k].id === data.lesson.rowid) {
              this.updateLessonViewed(section[i].lessons[k], this.courseObj);
            }
          }
        }
        this.checkCertEligibility(data.cert);
      }
    },
    /**
     * Move to next lesson in list after current lesson is viewed
     * @param {int} lessonid
     * @return {void}
     */
    lessonNext (lessonid) {
      let nextLesson = this.findNextLesson(lessonid);
      this.openLesson(nextLesson.sectionid, nextLesson);
    },
    findNextLesson (lessonid) {
      lessonid = lessonid || this.selectedLesson.rowid;
      let lastLessonIndex, nextLesson;

      if (this.courseObj.sections) {
        // Create a flat obj of course lessons
        let flatLessons = _.flatMap(this.courseObj.sections, (section) => {
          // Return all lessons for a section with section id
          return _.each(section.lessons, (lesson) => {
            // add section id to lesson
            lesson.sectionid = section.id;
            return lesson;
          });
        });

        // Find index of lastLesson
        lastLessonIndex = _.findIndex(flatLessons, {'id': lessonid});

        if (lastLessonIndex + 1 >= flatLessons.length) {
          nextLesson = flatLessons[lastLessonIndex];
        } else {
          nextLesson = flatLessons[lastLessonIndex + 1];
        }
      }
      return nextLesson;
    },
    /**
     * Check for last lesson in course
     */
    lessonFinal () {
      this.endOfCourse = false;
      let section = this.courseObj.sections;
      if (section) {
        for (let i = 0; i < section.length; i++) {
          for (let k = 0; k < section[i].lessons.length; k++) {
            if (section[i].lessons[k].id === this.selectedLesson.rowid) {
              if (
                section[i + 1] === undefined &&
                section[i].lessons[k + 1] === undefined
              ) {
                this.endOfCourse = true;
              }
            }
          }
        }
      }
    },
    /**
     * Checks if the member is cert elegible after taking the lesson and getting a score grater than threshold or viewing all lessons or both jcervantes 05/17/18
     * @param certData
     * @param assessmentid
     * @param certData
     * @param assessmentid
     */
    checkCertEligibility (certData, assessmentid) {
      window.removeEventListener('message', this.testComplete, false);
      if (certData.eligibility) {
        this.$store.commit('software/setCertStatus', {
          course: this.courseObj,
          cert: certData.cert,
        });
      }
    },
    /**
     * Commit to store lesson view
     * @param {obj} lesson
     * @param {obj} course
     * @return {void}
     */
    updateLessonViewed (lesson, course) {
      this.$store.commit('software/setLessonViewed', {
        lesson: lesson,
        course: course,
      });
    },
    /**
     * Sets cert modal_content, this is a prop that is sent to the player to trigger the cert modal jcervantes 05/24/18
     * @param {obj} course
     * @return {void}
     */
    setModalContent (course) {
      let hasTest;
      if (course.num_questions && course.num_questions > 0) {
        hasTest = ' and scored above ' + course.cert.score_threshold + '% on the Review Test.';
      } else {
        hasTest = '.';
      }
      //TODO: AHHHHHH!!!!!
      this.modalContent =
        '<div class="certs-popup m-4">' +
        '<div class="row">' +
        '<div class="col-xs-12 mx-auto mb-4">' +
        '<h2 class="display-5 mb5 mt2 text-center">Congratulations, you\'ve earned <br />a Technical Certificate!</h2>' +
        '</div>' +
        '</div>' +
        '<div class="row">' +
        '<div class="col-xs-12 mx-auto mb-4">' +
        '<img src="' + this.SPENV.APP_URL +
        '/certs/assets/images/badges/cert_badge_white.png" style="height:95px" />' +
        '</div>' +
        '</div>' +
        '<div class="row">' +
        '<div class="col-xs-12 mx-auto mb-4">' +
        '<p class="text-center">You watched every lesson in this course' + hasTest +
        '. You grew your skills and now have something to show for it!</p>' +
        '</div>' +
        '</div>' +
        '<div class="row">' +
        '<div class="col-xs-12 mx-auto mb-2">' +
        '<p><a class="cert_link" href="' + this.SPENV.APP_URL +
        '/lmsapp/achievements/cert/' + course.cert.id +
        '">View <span>your SolidProfessor Technical </span>Certificate</a></p>' +
        '</div>' +
        '</div>' +
        '</div>';
    },
    /**
     * Toggle lesson filter menu
     * @return {void}
     */
    toggleFilterMenu () {
      this.showFilterMenu = !this.showFilterMenu;
    },
    /**
     * Set lessonsFilter to incoming (changed) filter
     * @return {void}
     * @param filter
     */
    setLessonsFilter (filter) {
      this.filterValue = filter;
      this.toggleFilterMenu();
    },
    /**
     * Filter sections list by bookmarked, recommended or viewed
     * @return {void}
     * @param sections
     */
    applySectionFilters (sections) {
      // Return all lessons if filter == all
      if (this.filterValue === 'all') {
        return sections;
      }
      let sectionsArr = [];
      if (sections) {
        for (let i = 0; i < sections.length; i++) {
          for (let k = 0; k < sections[i].lessons.length; k++) {
            const result = sectionsArr.find(
              section => section.id === sections[i].id,
            );
            // Return viewed lessons
            if (this.filterValue === 'viewed' && !sections[i].lessons[k].viewed && !result) {
              sectionsArr.push(sections[i]);
              // Return recommended lessons
            } else if (
              this.filterValue === 'recommended' &&
              sections[i].lessons[k].recommended &&
              !result
            ) {
              sectionsArr.push(sections[i]);
              // Return bookmarked lessons
            } else if (this.filterValue === 'bookmarked' && sections[i].lessons[k].bookmark && !result
            ) {
              sectionsArr.push(sections[i]);
            } else if (this.filterValue === 'new' && this.isNew(sections[i].lessons[k].id) && !result) {
              sectionsArr.push(sections[i]);
            }
          }
        }
        return sectionsArr;
      }
    },
    /**
     * Filter lessons list by bookmarked, recommended or viewed
     * @return {void}
     * @param lessons
     */
    applyLessonFilters (lessons) {
      let lessonsArr = [];
      if (lessons) {
        for (let i = 0; i < lessons.length; i++) {
          // If unviewed lessons enable filter
          if (!lessons[i].viewed) {
            this.disabled.viewed = false;
          }
          // If bookmarked lessons enable filter
          if (lessons[i].bookmark) {
            this.disabled.bookmarked = false;
          }
          if (this.filterValue === 'viewed' && !lessons[i].viewed) {
            lessonsArr.push(lessons[i]);
          }
          if (this.filterValue === 'recommended' && lessons[i].recommended) {
            lessonsArr.push(lessons[i]);
          }
          if (this.filterValue === 'new' && this.isNew(lessons[i].id)) {
            lessonsArr.push(lessons[i]);
          }
          if (this.filterValue === 'bookmarked' && lessons[i].bookmark) {
            this.disabled.bookmarked = false;
            lessonsArr.push(lessons[i]);
          }
        }
      }
      // Return all lessons if filter == all
      if (this.filterValue === 'all') {
        return lessons;
      }
      return lessonsArr;
    },
    /**
     * Toggle theater video mode
     * @return {void}
     */
    toggleTheaterMode () {
      this.isTheaterModeToggled = !this.isTheaterModeToggled;
    },
    /**
     * Open test tab for course
     * @return {void}
     */
    openTest () {
      window.open(this.SPENV.APP_URL + '/weblms/test.asp?drmid=' + this.courseObj.id + '&popup=1', 'testingNGPopup', 'height=740, width=1024, directories=no, location=no, resizable=yes, menubar=no, toolbar=no, scrollbars=yes, status=no',);
      this.testComplete = (certData) => {
        this.checkCertEligibility(certData.data, certData.data.assessmentid);
        if (certData.data.test_score) {
          this.$store.commit('software/updateTestScore', {
            course: this.courseObj,
            test_score: certData.data.test_score,
          });
        }
      };
      window.addEventListener('message', this.testComplete, false);
    },
    /**
     * Get the partfiles url for a lesson and do download
     *
     * @param {int} lessonId
     * @return {void}
     */
    getPartfilesByLessonId (lessonId) {
      let downloadWindow = window.open('', '_blank');
      axios.get(this.SPENV.APP_URL + '/api/v2/lesson/' + lessonId + '/partfiles').then((response) => {
        downloadWindow.location = response.data.url;
      })
        .catch(error => {
          reject(error);
        });
    },
    fixSlugsFromSelectedLesson () {
      let selectedParams = {
        'swslug': this.selectedLesson.software_slug,
        'courseslug': this.selectedLesson.course_slug,
        'lessonid': this.selectedLesson.rowid,
      };
      this.$router.push({
        name: 'course',
        params: selectedParams,
        query: this.processQueryParams()
      });
    },
  },
};
</script>

<style lang="scss">
  .no-router-links {
    text-decoration: none;
    color: black;
    &:hover {
      text-decoration: none;
      color: black;
    }
  }
  .trans-btn {
    .btn {
      background-color: transparent !important;
      border-color: transparent !important;
      box-shadow: transparent !important;
      padding: 0 !important;

      &:focus,
      &.focus {
        box-shadow: transparent !important;
        color: transparent !important;
      }
    }
  }
  .wait {
    cursor: wait !important;
    .lesson-item:hover {
      cursor: wait !important;
    }
  }
</style>
